import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const ProcessThisAnalyzeThat = () => (
  <Layout
    title="Process This! Analyze That! Process Skills Strategies for Science
"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            Process This! Analyze That! Process Skills Strategies for Science
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">
              Process This! Analyze That! Process Skills Strategies for Science
            </h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <div className="videoWrapper">
                <Link
                  to="../storyboarding-successful-screen-debut"
                  className="previous"
                >
                  <Icon name="arrowleftgray" />
                </Link>
                <Video youTubeID="RN4Ax19bYfQ" className="youtube"></Video>
                <Link
                  to="../ysc-webinar-2012-winner-deepika-kurup"
                  className="next"
                >
                  <Icon name="arrowrightgray" />
                </Link>
              </div>
            </div>
            <h3>
              Discovery Education's Patti Duncan shares this fun webinar on
              developing process skills in students, especially in science.
              Patti will tie in the 3M Discovery Education Young Scientist
              Challenge at the end.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default ProcessThisAnalyzeThat;
